import extend from 'extend';

import BH from 'bh-custom';

function getRel(location) {
    let rel = ['nofollow'];

    if (/^https?:/.test(location)) {
        rel.push('noopener');
    }

    return rel.join(' ');
}

export default function initTemplates(i18n) {
    const bh = new BH();

    /* eslint-disable camelcase, max-params */
    const templates = {
        container: ({ urls, theme, services }) => ({
            mods: { size: theme.size, 'color-scheme': theme.colorScheme, shape: theme.shape },
            content: [
                elem('list', theme.direction, services, theme.limit, urls.content, theme.copy, theme.popupDirection, theme.moreButtonType)
            ]
        }),

        list: (direction, services, limit = services.length, url = '', copyPos, popupDirection, moreButtonType) => {
            if (limit === false) {
                limit = services.length;
            }

            const visible = services.slice(0, limit);
            const hidden = services.slice(limit);

            return {
                tag: 'ul',
                mods: { direction },
                content: [
                    visible.map(service => elem('item', service)),
                    hidden.length === 0 && copyPos === 'extraItem' && elem('item_extra_copy', url),
                    hidden.length > 0 && elem('item_more', hidden, url, copyPos, popupDirection, direction, limit === '0' && moreButtonType)
                ]
            };
        },

        item: (service = {}) => ({
            tag: 'li',
            mods: { service: service.name },
            content: elem('link', service)
        }),

        link: ({ location, title, mods, linkAttrs } = {}) => ({
            tag: 'a',
            attrs: {
                href: location || '#',
                rel: location && getRel(location),
                target: location && '_blank',
                title: title,
                ...linkAttrs
            },
            mods,
            content: [
                elem('badge'),
                elem('title', title)
            ]
        }),

        badge: () => ({
            tag: 'span',
            content: [
                elem('icon')
            ]
        }),

        icon: () => ({
            tag: 'span'
        }),

        title: title => ({
            tag: 'span',
            content: title
        }),

        item_more: (services, url, copyPos, popupDirection, listDirection, moreButtonType) => extend(elem('item'), {
            mods: { more: true, 'has-pretty-view': Boolean(moreButtonType) },
            content: [
                elem('link_more', moreButtonType),
                elem('popup', services, url, copyPos, popupDirection, listDirection)
            ]
        }),

        link_more: moreButtonType => extend(elem('link'), {
            mods: { more: true, 'more-button-type': moreButtonType },
            content: [
                elem('badge_more'),
                moreButtonType === 'long' && elem('title', i18n.shareButton)
            ]
        }),

        badge_more: () => extend(elem('badge'), {
            mods: { more: true },
            content: elem('icon_more')
        }),

        icon_more: () => extend(elem('icon'), {
            mods: { more: true }
        }),

        item_copy: url => extend(elem('item'), {
            mods: { copy: true },
            content: [
                elem('link', { title: i18n.copyLink, mods: { copy: true }}),
                elem('input_copy', url)
            ]
        }),

        item_extra_copy: url => extend(elem('item'), {
            mods: { copy: true },
            content: [
                elem('link', { title: i18n.copyLink }),
                elem('input_copy', url)
            ]
        }),

        input_copy: url => ({
            tag: 'input',
            attrs: {
                value: url
            }
        }),

        popup: (services, url, copyPos = 'last', popupDirection, listDirection) => {
            const list = elem('list', 'vertical', services);

            if (copyPos === 'first') {
                list.content.unshift(elem('item_copy', url));
            } else if (copyPos === 'last') {
                list.content.push(elem('item_copy', url));
            } // Do nothing if copyPos === 'hidden'

            popupDirection = popupDirection === 'top' ?
                'top' :
                popupDirection === 'auto' ?
                    'auto' :
                    'bottom';
            listDirection = listDirection === 'vertical' ? 'vertical' : 'horizontal';

            return {
                mods: {
                    direction: popupDirection,
                    'list-direction': listDirection
                },
                content: list
            };
        }
    };
    /* eslint-enable camelcase, max-params */

    function elem(name, ...params) {
        return extend({
            block: 'ya-share2',
            elem: name
        }, templates[name](...params));
    }

    return {
        update: (context, name, params = []) => {
            context.innerHTML = bh.toHtml(elem(name, ...params));
        }
    };
}
