/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'viber://forward',
                params: {
                    text: {
                        options: ['title', 'url'],
                        separator: ' '
                    }
                }
            }
        }
    },

    i18n: {
        az: 'Viber',
        be: 'Viber',
        en: 'Viber',
        hy: 'Viber',
        ka: 'Viber',
        kk: 'Viber',
        ro: 'Viber',
        ru: 'Viber',
        tr: 'Viber',
        tt: 'Viber',
        uk: 'Viber',
        uz: 'Viber'
    },

    color: '#7b519d'
};
