/**
 * Load plugins from given directory
 *
 * Reads json configs from all *.js files in given directory
 * and concats it into one object
 *
 * @param  {String} pluginsDir
 * @return {Object}
 */
export function loadPlugins() {
    const context = require.context('../../../plugins', false, /^\.\/.*\.js$/);

    return context.keys().reduce(function (acc, key) {
        const parsedKey = key.match(/^\.\/(\w+)\.js/);

        if (parsedKey) {
            acc[parsedKey[1]] = context(key).default;
        }

        return acc;
    }, {});
}

export function getCss(plugins) {
    const commonCss = require('raw-loader!../../css/share.styl').default;

    return commonCss + Object.keys(plugins).map(name => {
        const icon = require(`svg-url-loader?stripdeclarations!../../../plugins/${name}.svg`);

        return `
.ya-share2__item_service_${name} .ya-share2__badge
{
    background-color: ${plugins[name].color};
}

.ya-share2__item_service_${name} .ya-share2__icon
{
    background-image: url("${icon}");
}

.ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_${name} .ya-share2__icon
{
    background-image: url("${icon.replace('fill=\'%23FFF\'', 'fill=\'%23000\'')}");
}
`;
    }).join('');
}
