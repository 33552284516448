import dom from './dom';

function createInput(text) {
    var input = document.createElement('input');

    input.setAttribute('type', 'text');
    input.setAttribute('value', text);

    input.style.position = 'absolute';
    input.style.left = '-9999px';

    document.body.appendChild(input);

    return input;
}

export function copy() {
    try {
        return document.execCommand('copy');
    } catch (_) {
        return false;
    }
}

export function clip(text, fallback) {
    const input = createInput(text);

    input.select();

    const success = copy();

    dom.remove(input);

    if (!success) {
        fallback(text);
    }
}
