/* eslint-disable camelcase */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://www.facebook.com/sharer.php?src=sp',
                params: {
                    u: 'url',
                    title: 'title',
                    description: 'description',
                    picture: 'image'
                }
            },
            share: {
                baseUrl: 'https://www.facebook.com/dialog/share?display=popup',
                params: {
                    app_id: 'appId',
                    href: 'url',
                    redirect_uri: 'nextUrl'
                }
            },
            feed: {
                baseUrl: 'https://www.facebook.com/dialog/feed?display=popup',
                params: {
                    app_id: 'appId',
                    link: 'url',
                    next: 'nextUrl',
                    name: 'title',
                    description: 'description',
                    picture: 'image'
                }
            }
        }
    },

    contentOptions: {
        accessToken: '',
        appId: '',
        nextUrl: ''
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Facebook',
        be: 'Facebook',
        en: 'Facebook',
        hy: 'Facebook',
        ka: 'Facebook',
        kk: 'Facebook',
        ro: 'Facebook',
        ru: 'Facebook',
        tr: 'Facebook',
        tt: 'Facebook',
        uk: 'Facebook',
        uz: 'Facebook'
    },

    color: '#3b5998'
};
/* eslint-enable camelcase */
