/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://yandex.ru/collections/share/',
                params: {
                    url: 'url',
                    image: 'image',
                    description: 'title'
                }
            }
        }
    },

    popupDimensions: [994, 576],

    i18n: {
        az: 'Yandex.Collections',
        be: 'Яндэкс.Калекцыi',
        en: 'Yandex.Collections',
        hy: 'Yandex.Collections',
        ka: 'Yandex.Collections',
        kk: 'Yandex.Collections',
        ro: 'Yandex.Collections',
        ru: 'Яндекс.Коллекции',
        tr: 'Yandex.Collections',
        tt: 'Yandex.Collections',
        uk: 'Yandex.Collections',
        uz: 'Yandex.To‘plam'
    },

    color: '#f55'
};
