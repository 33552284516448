import './lib/polyfills';

import config from '../../config';
import { loadPlugins } from './lib/builder';
import Metrika from './lib/metrika';
import buildFacade from './lib/facade';

const plugins = loadPlugins();

const metrika = new Metrika(config.metrika.id);
const defaults = config.defaults;

import { ready } from './lib/document';

const createShare = buildFacade({ defaults, plugins, metrika });

ready(() => {
    metrika.init();
    createShare('.ya-share2', { reinit: false });
});

window.Ya = window.Ya || {};
window.Ya.share2 = function (container, params) {
    if (typeof container === 'object' && container.nodeType === 1) {
        return createShare(container, params)[0];
    }

    if (typeof container === 'string') {
        if (container.indexOf('#') === 0) {
            console.log('DEPRECATION: use element id instead of query selector for initialization');
            container = container.slice(1);
        }

        return createShare('#' + container, params)[0];
    }

    throw new TypeError('Neither element nor element id is provided');
};
