/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://api.whatsapp.com/send',
                params: {
                    text: {
                        options: ['title', 'url'],
                        separator: ' '
                    }
                }
            }
        }
    },

    i18n: {
        az: 'WhatsApp',
        be: 'WhatsApp',
        en: 'WhatsApp',
        hy: 'WhatsApp',
        ka: 'WhatsApp',
        kk: 'WhatsApp',
        ro: 'WhatsApp',
        ru: 'WhatsApp',
        tr: 'WhatsApp',
        tt: 'WhatsApp',
        uk: 'WhatsApp',
        uz: 'WhatsApp'
    },

    color: '#65bc54'
};
