export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://connect.ok.ru/offer',
                params: {
                    url: 'url',
                    title: 'title',
                    description: 'description',
                    imageUrl: 'image'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Odnoklassniki',
        be: 'Одноклассники',
        en: 'Odnoklassniki',
        hy: 'Odnoklassniki',
        ka: 'Odnoklasniki',
        kk: 'Одноклассники',
        ro: 'Odnoklassniki',
        ru: 'Одноклассники',
        tr: 'Odnoklasniki',
        tt: 'Одноклассники',
        uk: 'Однокласники',
        uz: 'Odnoklassniki'
    },

    color: '#eb722e'
};
