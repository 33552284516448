export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://twitter.com/intent/tweet',
                params: {
                    text: 'title',
                    url: 'url',
                    hashtags: 'hashtags',
                    via: 'via'
                }
            }
        }
    },

    contentOptions: {
        hashtags: '',
        via: ''
    },

    popupDimensions: [550, 420],

    i18n: {
        az: 'Twitter',
        be: 'Twitter',
        en: 'Twitter',
        hy: 'Twitter',
        ka: 'Twitter',
        kk: 'Twitter',
        ro: 'Twitter',
        ru: 'Twitter',
        tr: 'Twitter',
        tt: 'Twitter',
        uk: 'Twitter',
        uz: 'Twitter'
    },

    color: '#00aced'
};
