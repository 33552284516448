import { injectJs } from './document';

function createCounter(id, callback = () => {}) {
    try {
        window[`yaCounter${id}`] = new window.Ya.Metrika2({
            id: id,
            trackLinks: true,
            accurateTrackBounce: true,
            params: {
                shareVersion: 2,
                redesign: 1
            },
            triggerEvent: true
        });

        callback();
    } catch (_) {
        // ignore error
    }
}

function pushCallback(callback) {
    const namespace = 'yandex_metrika_callbacks2';

    window[namespace] = window[namespace] || [];
    window[namespace].push(callback);
}

class Metrika {
    constructor(id) {
        this._id = id;
    }

    /**
     * Init Yandex.Metrika. Insert it into the page if not present
     */
    init() {
        if (window.Ya && 'Metrika2' in window.Ya) {
            createCounter(this._id);
        } else {
            const script = injectJs('https://mc.yandex.ru/metrika/tag.js');

            pushCallback(() => {
                createCounter(this._id, () => script && script.parentNode.removeChild(script));
            });
        }
    }

    /**
     * Get Yandex.Metrika counter
     *
     * @return {Object}
     */
    getCounter() {
        return window[`yaCounter${this._id}`];
    }
}

export default Metrika;
